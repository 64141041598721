<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">All Depostis</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <button class="btn btn-info float-right" @click="showCreateModal"><i class="fas fa-plus-circle"></i> Create</button>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <form>
                                <div class="row">
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12" v-if="authUser.role_id <= 2">
                                        <div class="form-group">
                                            <label for="title">{{ $t('globalTrans.hub') }}</label>
                                            <v-select name="hub_id"
                                                v-model="search.hub_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= hubList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="start_date">Start Date</label>
                                            <input type="date" id="start_date" v-model="search.start_date" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="end_date">End Date</label>
                                            <input type="date" id="end_date" v-model="search.end_date" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="deposit_method">Deposit Method</label>
                                            <v-select name="deposit_method"
                                                v-model="search.deposit_method"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= depositMethods
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12" v-if="search.deposit_method == 3">
                                        <div class="form-group">
                                            <label for="carried_by">Carried By</label>
                                            <v-select name="carried_by"
                                                v-model="search.carried_by"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= riderList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group" style="margin-top:28px;">
                                            <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="searchData"><i class="fa fa-search"></i></button>
                                            <a class="btn btn-warning btn-sm" target="_blank" :href="$baseUrl + 'excel-download/hub-deposit?start_date='+search.start_date+'&end_date='+search.end_date+'&hub_id='+search.hub_id">
                                                <i class="fa fa-download"></i> Excel
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="card-body">
                            <b-overlay :show="loader">
                                <div class="table-responsive">
                                    <table class="table table-bordered table-striped table-sm">
                                        <thead>
                                            <tr class="text-center">
                                                <th>Total Deposit Item</th>
                                                <th>Rider Advanced</th>
                                                <th>Hub Cost</th>
                                                <th>Collection Pending Amount</th>
                                                <th>Deposit Pending Amount</th>
                                                <th>Total Deposit Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="summary">
                                            <tr class="text-center text-bold">
                                                <td>{{ summary.total_deposit_item }}</td>
                                                <td>{{ summary.total_rider_advanced }}</td>
                                                <td>{{ summary.total_hub_cost }}</td>
                                                <td>{{ summary.collection_pending }}</td>
                                                <td>{{ summary.deposit_pending }}</td>
                                                <td>{{ summary.total_deposit_amount }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </b-overlay>
                        </div>
                        <div class="card-body">
                            <b-overlay :show="loader">
                                <div class="table-responsive">
                                    <table class="table table-bordered table-striped table-sm">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Date</th>
                                                <th>Collected Amount</th>
                                                <th>Hub Cost</th>
                                                <th>Rider Advanced</th>
                                                <th>Deposited Amount</th>
                                                <th>Available Amount</th>
                                                <th>Trnx ID</th>
                                                <th>Deposit Method</th>
                                                <th>Note</th>
                                                <th>Carried By</th>
                                                <th>Created By</th>
                                                <th>Updated By</th>
                                                <th>Status</th>
                                                <th style="text-align:center;">Action</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th>ID</th>
                                                <th>Date</th>
                                                <th>Collected Amount</th>
                                                <th>Hub Cost</th>
                                                <th>Rider Advanced</th>
                                                <th>Deposited Amount</th>
                                                <th>Available Amount</th>
                                                <th>Trnx ID</th>
                                                <th>Deposit Method</th>
                                                <th>Note</th>
                                                <th>Carried By</th>
                                                <th>Created By</th>
                                                <th>Updated By</th>
                                                <th>Status</th>
                                                <th style="text-align:center;">Action</th>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            <tr v-for="(item, index) in listData" :key="index">
                                                <td>{{ item.id }}</td>
                                                <td>{{ item.date | dateformat }}</td>
                                                <td>{{ item.collected_amount }}</td>
                                                <td>{{ item.hub_cost }}</td>
                                                <td>{{ item.rider_advanced }}</td>
                                                <td>{{ item.amount }}</td>
                                                <td>{{ item.available_amount }}</td>
                                                <td>{{ item.trnxId }}</td>
                                                <td>{{ getDepositMethod(item.deposit_method) }}</td>
                                                <td>{{ item.note }}</td>
                                                <td>{{ item.carried_by != null ? item.carriedby.name : '' }}</td>
                                                <td>{{ item.createdby ? item.createdby.name : '' }}</td>
                                                <td>{{ item.updatedby ? item.updatedby.name : '' }}</td>
                                                <td :class="'cost-status-'+ item.status">{{ getStatus(item.status) }}</td>
                                                <td style="text-align:center;">
                                                    <slot v-if="item.status == 1">
                                                        <button class="btn btn-primary btn-sm mr-1" @click="showOrder(item.id)" title="Show Order"> <i class="fa fa-eye"></i> </button> 
                                                        <!-- <button class="btn btn-warning btn-sm mr-1"  @click="editData(item)"><i class="fas fa-pencil-alt"></i></button> -->
                                                        <!-- <button class="btn btn-danger btn-sm" @click="deleteData(key, item.id)"><i class="fas fa-trash"></i></button> -->
                                                    </slot>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>           
                                </div>             
                                <div class="mt-3">
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                    />
                                </div>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- /.container-fluid -->
        </section>
        <div v-if="createModal" class="modal fade show" style="display:block" tabindex="-1" id="addHubModal" Hub="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" Hub="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Create New Depost</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ValidationObserver ref="createForm" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(saveForm)">
                                    <div class="row">
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Collected Amount" vid="collected_amount" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="collected_amount">Collected Amount <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="collected_amount" v-model="form.collected_amount" class="form-control" placeholder="Hub Cost" readonly />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Hub Cost" vid="hub_cost" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="hub_cost">Hub Cost <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="hub_cost" v-model="form.hub_cost" class="form-control" placeholder="Hub Cost" readonly />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Rider Advanced" vid="rider_advanced" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="rider_advanced">Rider Advanced <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="rider_advanced" v-model="form.rider_advanced" class="form-control" placeholder="Rider Advanced" readonly />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>                                        
                                       <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Date" vid="date" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="date">Date <span class="text-danger" title="Required">*</span></label>
                                                    <input type="date" id="date" v-model="form.date" class="form-control" required disabled />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Amount" vid="amount" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="mobile">Amount <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="amount" v-model="form.amount" class="form-control" placeholder="Amount" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Available" vid="available_amount" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="available_amount">Available <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="available_amount" v-model="form.available_amount" class="form-control" disabled />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Deposit Method" vid="deposit_method" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="deposit_method">Deposit Method <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="deposit_method"
                                                        v-model="form.deposit_method"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= depositMethods
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12" v-if="form.deposit_method == 3">
                                            <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="hub_id">Carried By <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="carried_by"
                                                        v-model="form.carried_by"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= riderList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div> 
                                    <div class="form-group" v-if="form.deposit_method != 3">
                                        <ValidationProvider name="Trnx ID" vid="trnxId" v-slot="{errors}" rules="required">
                                            <label for="note">Trnx ID <span class="text-danger" title="Required">*</span></label>
                                            <input type="text" id="trnxId" v-model="form.trnxId" class="form-control" placeholder="Transaction ID" />
                                            <span class="text-danger">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group">
                                        <ValidationProvider name="Note" vid="note" v-slot="{errors}" rules="required">
                                            <label for="note">Note <span class="text-danger" title="Required">*</span></label>
                                            <textarea id="note" v-model="form.note" class="form-control" placeholder="Note maximum 200 word"> </textarea>
                                            <span class="text-danger">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="saveForm" v-if="form.amount <= form.collected_amount">Save</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="editModal" class="modal fade show" style="display:block" tabindex="-1" id="editDataModal" Hub="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" Hub="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Edit Hub</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">                            
                            <ValidationObserver ref="editForm" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(updateForm)">
                                    <div class="row">
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Collected Amount" vid="collected_amount" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="collected_amount">Collected Amount <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="collected_amount" v-model="form_edit.collected_amount" class="form-control" placeholder="Hub Cost" readonly />
                                                    <input type="hidden" id="date" v-model="form_edit.id" class="form-control" required />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Hub Cost" vid="hub_cost" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="hub_cost">Hub Cost <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="hub_cost" v-model="form_edit.hub_cost" class="form-control" placeholder="Hub Cost" readonly />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Rider Advanced" vid="rider_advanced" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="rider_advanced">Rider Advanced <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="rider_advanced" v-model="form_edit.rider_advanced" class="form-control" placeholder="Rider Advanced" readonly />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Date" vid="date" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="date">Date <span class="text-danger" title="Required">*</span></label>
                                                    <input type="date" id="date" v-model="form_edit.date" class="form-control" required />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Amount" vid="amount" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="mobile">Amount <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="amount" v-model="form_edit.amount" class="form-control" placeholder="Amount" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Available" vid="available_amount" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="available_amount">Available <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="available_amount" v-model="form_edit.available_amount" class="form-control" disabled />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Deposit Method" vid="deposit_method" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="deposit_method">Deposit Method <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="deposit_method"
                                                        v-model="form_edit.deposit_method"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= depositMethods
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12" v-if="form_edit.deposit_method == 3">
                                            <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="hub_id">Carried By <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="carried_by"
                                                        v-model="form_edit.carried_by"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= riderList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div> 
                                    <div class="form-group">
                                        <ValidationProvider name="Trnx ID" vid="trnxId" v-slot="{errors}" rules="required" v-if="form_edit.deposit_method != 3">
                                            <label for="note">Trnx ID <span class="text-danger" title="Required">*</span></label>
                                            <input type="text" id="trnxId" v-model="form_edit.trnxId" class="form-control" placeholder="Transaction ID" />
                                            <span class="text-danger">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group">
                                        <ValidationProvider name="Note" vid="note" v-slot="{errors}" rules="required">
                                            <label for="note">Note <span class="text-danger" title="Required">*</span></label>
                                            <textarea id="note" v-model="form_edit.note" class="form-control" placeholder="Note maximum 200 word"> </textarea>
                                            <span class="text-danger">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="updateForm">Update</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="deleteModal" class="modal fade show" style="display:block" tabindex="-1" id="deleteDataModal" Hub="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-default" Hub="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Delete Confirmation</h5>
                        </div>
                        <div class="modal-body">
                            <input type="hidden" name="del_id" />
                            <h3 class="text-center">Are you sure to delete ?</h3>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click="destroyData">Delete</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <!-- /.content -->
    </div>
</template>
<script>
import config from '@/config'
import moment from 'moment'
export default {
    name:'Hub',
    data () {
        return {
            loader: false,
            form:{
                hub_id: '',
                date  : moment().format('YYYY-MM-DD'),
                hub_cost: '',
                hub_cost_ids: '',
                rider_advanced: '',
                rider_advanced_ids: '',
                collected_amount: '',
                amount: '',
                available_amount: 0,
                deposit_method: '',
                carried_by: null,
                trnxId: '',
                note: ''
            },
            summary: null,
            search: {
                hub_id: '',
                deposit_method: '',
                carried_by: '',
                start_date: moment().subtract(30,'d').format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD')
            },
            depositMethods: [
                { id: 1, text: 'Bank' },
                { id: 2, text: 'Bkash' },
                { id: 3, text: 'Cash' },
            ],
            form_edit:'',
            itemId: 0,
            errors: [],
            createModal: false,
            editModal: false,
            deleteModal: false,
            key: '',
            pagination: {
                currentPage: 1,
                totalRows: 0,
                perPage: this.$store.state.commonObj.perPage,
                slOffset: 1
            }
        }
    },
    created(){
        this.loadSummary()
        this.loadData()
    },
    watch: {
        stateReload : function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.loadSummary()
                this.loadData()
            }
        },
        'form.amount': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.form.available_amount = (this.getAvailableBalance(newVal) - parseFloat(this.form.hub_cost + this.form.rider_advanced))
            }
        },
        'form_edit.amount': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.form_edit.available_amount = (this.getEditAvailableBalance(newVal) - parseFloat(this.form_edit.hub_cost + this.form_edit.rider_advanced))
            }
        }
    },
    computed : {
        stateReload () {
            return this.$store.state.stateReload
        },
        listData () {
            return this.$store.state.list
        },
        hubList () {
            return this.$store.state.commonObj.hubList
        },
        authUser () {
            return this.$store.state.authUser
        },
        riderList () {
            return this.$store.state.commonObj.riderList.filter(el => el.hub_free == 1)
        }
    },
    methods:{
        searchData () {
            this.loadSummary()
            this.loadData()
        },
        async loadSummary () {
            this.loader = true
            const params = this.authUser.role_id == 7 ? Object.assign({}, this.search, { hub_id: this.authUser.hub_id }) : this.search 
            const response = await config.getData('/hub/deposit/summary', params)
            this.loader = false
            this.summary = response.data          
        },
        async loadData(){     
            this.loader = true 
            const params = this.authUser.role_id == 7 ? Object.assign({}, this.search, { hub_id: this.authUser.hub_id }) : Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage }) 
            const response = await config.getData('/hub/deposit', params)
            this.loader = false
            if (response.success){
                this.$store.dispatch('setList', response.data.data)
                this.paginationData(response.data)
            } else {
                this.$store.dispatch('setList', [])
            } 
        },
        paginationData (data) {
            this.pagination.currentPage = data.current_page
            this.pagination.totalRows = data.total
            this.pagination.perPage = data.per_page
            this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
        },
        async showCreateModal(){
            // this.createModal = true
            this.loader = true        
            const response = await config.getData(`/hub/deposit/check-cost/${this.authUser.hub_id}`)
            this.loader = false
            if (response.success){
                // const available_amount = parseFloat(response.data.collected_amount - (response.data.rider_advanced + response.data.hub_cost))
                // this.form = Object.assign(this.form, response.data, { available_amount: available_amount })
                this.$router.push('/hub-depositable')
            } else {
                // this.createModal = false
               this.$toast.error({
                    title: 'Error',
                    message: response.message,
                    color: '#dc3545'
                })
            } 
        },
        async saveForm() {
            this.loader = true
            this.$store.dispatch('stateReload', true)    
            this.form = Object.assign(this.form, { hub_id: this.authUser.hub_id })
            const response = await config.postData('/hub/deposit/store', this.form)
            this.loader = false
            if (response.success) {
                this.createModal = false;
                this.form.amount = this.form.trnxId = this.form.note = ''  
                this.$toast.success({
                    title: 'Success',
                    message: 'Data created successfully',
                    color: '#218838'
                }) 
            } else {
                this.$refs.createForm.setErrors(response.errors)
                this.$toast.error({
                    title: 'Error',
                    message: 'Sorry, something went wrong',
                    color: '#dc3545'
                })   
            }
            this.$store.dispatch('stateReload', false)
        },
        cancelModal(){
            this.createModal = false
            this.editModal = false
            this.deleteModal = false
        },
        editData (hub) {
            this.editModal = true
            this.form_edit = JSON.parse(JSON.stringify(hub))
        },
        async updateForm(){
            this.loader = true
            this.$store.dispatch('stateReload', true)
            const response = await config.postData('/hub/deposit/update', this.form_edit)            
            this.loader = false
            if (response.success) {
                this.editModal = false         
                this.$toast.success({
                    title: 'Success',
                    message: 'Data updated successfully',
                    color: '#218838'
                }) 
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: response.message,
                    color: '#dc3545'
                })   
            }
            this.$store.dispatch('stateReload', false)
        },
        deleteData(key, itemId){
            this.itemId = itemId;
            this.key = key;
            this.deleteModal = true;
        },
        async destroyData(){
            this.loader = true
            this.$store.dispatch('stateReload', true)
            await config.deleteData('/hub/deposit/trash/'+ this.itemId)
            this.loader = false         
            this.deleteModal = false
            this.$toast.success({
                title: 'Success',
                message: 'Data deleted successfully',
                color: '#218838'
            }) 
            this.$store.dispatch('stateReload', false)
        },
        getDepositMethod (status) {
            if (status == 1) {
                return 'Bank'
            } else if (status == 2) {
                return 'Bkash'
            } else if (status == 3) {
                return 'Cash'
            }
        },
        getStatus (status) {
            if (status == 1) {
                return 'Pending'
            } else if (status == 2) {
                return 'Approved'
            } else if (status == 3) {
                return 'Edited'
            }  else if (status == 4) {
                return 'Deleted'
            }
        },
        getAvailableBalance (depositAmount) {
            return parseFloat(this.form.collected_amount - depositAmount)
        },
        getEditAvailableBalance (depositAmount) {
            return parseFloat(this.form_edit.collected_amount - depositAmount)
        },
        showOrder (depositId) {
            this.$router.push({ name: 'hub.deposited', query: { deposit_id: depositId } })
        }
    }
}
</script>